<template>
    <b-modal id="showmodal" v-model="examDiv" title="选择考试名称" size="lg" centered title-class="font-18" hide-footer>
        <div class="flexList">
            <el-select class="mr-2" style="width: 20%" placeholder="请选择考试年份" v-model="examParam.ksnf" size="small">
                <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                </el-option>
            </el-select>
            <input placeholder="关键字" class="h30 form-control border-blue mr-2" v-model="examParam.ksmc" maxlength="50"
                   style="width: 156px"/>
            <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick">
                <i class="iconfont mr-2 icon-mb-search"></i>查询
            </button>
        </div>
        <div class="table-responsive border mt-3">
            <table class="table light-table table-hover">
                <thead class="thead-light">
                <tr>
                    <th>序号</th>
                    <th>年份</th>
                    <th>考试名称</th>
                    <th>选择</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in examList" :key="item.sid">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.ksnf }}</td>
                    <td>
                        <a href="javascript:void(0)" @click="changeExam(item)" class="blue-font">{{ item.ksmc }}</a>
                    </td>
                    <td>
                        <b-button size="sm" variant="info" @click="changeExam(item)">切换</b-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mb-2 text-right">
            <el-pagination @size-change="handleSizeChange" background small @current-change="handleCurrentChange"
                           :current-page.sync="examParam.pageNum" :page-size="examParam.pageSize"
                           layout="prev, pager, next"
                           :total="examParam.total">
            </el-pagination>
        </div>

    </b-modal>
</template>

<script>
    import {
        getExamEasy,
        getExamRecord,
        setNowExam,
    } from "@/api/admin/exam/examRecord.js";
    import {
        getNowExam
    } from "@/api/admin/exam/examRecord";

    export default {
        name: "change-exam",
        props: {
            ksbmbh: {
                type: String,
                default: ""
            },
            examDivShow: {
                type: Boolean,
                default: false
            },

        },
        data() {
            return {
                examDiv: this.examDivShow,
                examParam: {
                    ksnf: "",
                    ksmc: "",
                    pageNum: 1,
                    pageSize: 10,
                    total: 0
                },
                examList: [],
                years: [],
                changExam: false,
                baseConfig: {},
            }
        },
        methods: {
            getYear() {
                //获取年份，当前年前后几年
                var y = new Date().getFullYear();
                for (var i = 0; i <= 5; i++) {
                    if (i < 5) {
                        this.years.unshift({
                            value: y - i,
                            label: y - i,
                        });
                    } else {
                        for (var x = 1; x < 2; x++) {
                            this.years.push({
                                value: y + x,
                                label: y + x,
                            });
                        }
                    }
                }
              this.years.sort((a, b) => b.value - a.value);
            },
            searchClick() { //搜索时先将分页的页码归1
                this.examParam.pageNum = 1; //当前页
                this.getExamList() //渲染数据的函数
            },
            // 获取考试列表
            getExamList() {
                getExamRecord(this.examParam).then((res) => {
                    if (res.status) {
                        this.examList = res.data;
                        this.examParam.total = res.total;
                        if (res.data.length) {
                            let ksbmbh = res.data[0].ksbmbh
                            getNowExam().then(res => {
                                if (res.status) {
                                    if (!res.data) {
                                        setNowExam(ksbmbh).then(res => {
                                            if (res.status) {
                                                window.location.reload()
                                            }
                                        })
                                    }
                                }
                            })
                        }

                    }
                });
            },

            // 切换考试
            changeExam(info) {
                setNowExam(info.ksbmbh).then(res => {
                    if (res.status) {
                        window.location.reload()
                    }
                })
            },
            handleSizeChange(val) {
                this.examParam.pageSize = val
                this.getExamList()
            },
            handleCurrentChange(val) {
                this.examParam.pageNum = val
                this.getExamList()
            },
        },
        mounted() {
            this.getYear()
            this.getExamList()
        },
        created() {
            let conf = JSON.parse(window.localStorage.getItem('BaseConfig'))
            this.baseConfig = conf ? conf : {}
            if (this.baseConfig) {
                this.examParam.ksnf = this.baseConfig.SystemYear?this.baseConfig.SystemYear:new Date().getFullYear()

            }
        }
    }
</script>

<style scoped>

</style>
