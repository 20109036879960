// 各种库


/* *****************分割************** */

// 报名库
import { getRequest,postRequest,downloadRequest,uploadFileDbf,deleteRequest,putRequest} from "@/libs/axios";

// 考试库列表
export const getKsbmk = params => {
  return getRequest("/yethan/register/ksbmk/listPage", params);
};
//创建考试报名库
export const createKsbmb = params => {
  return postRequest("/yethan/register/ksbmk/createKsbmb", params);
};


/* *****************分割************** */


// 查询考试档案库
export const getCjdak = params => {
  return getRequest("/yethan/register/cjdak/listPage", params);
};

// 下载考试档案库
export const DownloadCjdak = params => {
  return downloadRequest("/yethan/register/cjdak/downloadTemplate", params);
};

// 考试档案库上传
export const uploadDbf = params => {
  return uploadFileDbf("/yethan/register/cjdak/uploadDbf", params.file,params.ksbmbh);
};

//创建考试档案库
export const creatKscjdak = params => {
  return postRequest("/yethan/register/cjdak/creatKscjdak", params);
};

// 档案库修改
export const editCjdak = params => {
  return putRequest("/yethan/register/cjdak/edit", params);
};
// 档案库删除
export const deleteCjdak= params => {
  return deleteRequest("/yethan/register/cjdak/del",params);
}


/* *****************分割************** */


// 查询考试违纪库
export const getWjk = params => {
  return getRequest("/yethan/register/wjk/listPage", params);
};
//创建考试违纪库
export const creatKswjk = params => {
  return postRequest("/yethan/register/wjk/creatKswjk", params);
};
// 违纪库上传
export const uploadwjkDbf = params => {
  return uploadFileDbf("/yethan/register/wjk/uploadDbf", params.file,params.ksbmbh);
};
// 下载违纪库
export const DownloadWjk = params => {
  return downloadRequest("/yethan/register/wjk/downloadTemplate", params);
};

// 违纪库修改
export const editWjk= params => {
  return putRequest("/yethan/register/wjk/edit", params);
};
// 违纪库删除
export const deleteWjk= params => {
  return deleteRequest("/yethan/register/wjk/del",params);
}


/* *****************分割************** */



// 查询考试黑名单库
export const getHmdk = params => {
  return getRequest("/yethan/register/hmdk/listPage", params);
};
//创建考试黑名单库
export const creatKshmdk = params => {
  return postRequest("/yethan/register/hmdk/creatKshmdk", params);
};
//考试黑名单库上传
export const uploadHmdkDbf = params => {
  return uploadFileDbf("/yethan/register/hmdk/uploadDbf", params);
};
// 黑名单修改
export const editHmdk = params => {
  return putRequest("/yethan/register/hmdk/edit", params);
};
// 黑名单删除
export const deleteHmdk= params => {
  return deleteRequest("/yethan/register/hmdk/del",params);
}

/* *****************分割************** */


// 查询考试特殊名单库
export const getTsmdk = params => {
  return getRequest("/yethan/register/userSpecial/listPage", params);
};
//添加试特殊名单库
export const addTsmdk = params => {
  return postRequest("/yethan/register/userSpecial", params);
};

// 特殊名单修改
export const editTsmdk = params => {
  return putRequest("/yethan/register/userSpecial", params);
};
// 特殊名单删除
export function deleteTsmdk(sids) {
  return deleteRequest("/yethan/register/userSpecial/" + sids);
}

/* *****************分割************** */

// 判断是否存在该报名库

export function hasTable(ksbmbh) {
  return getRequest("/yethan/register/field/tableCount/"+ksbmbh);
}
// 删除已存在该报名库
export function removeBmk(ksbmbh) {
  return deleteRequest("/yethan/register/ksbmk/removeBmk/"+ksbmbh);
}

// 成绩单样式和准考证样式
export const getStyle = params => {
  return getRequest("/yethan/print/printTemplate/listPage", params);
};


/* *****************分割************** */

// 查询考试职位库
export const getZwk = params => {
  return getRequest("/yethan/register/examZwk/listPage", params);
};
//创建考试职位库
export const  creatKszwk = params => {
  return postRequest("/yethan/register/examZwk/creatKszwk", params);
};

// 职位库修改
export const editZwk = params => {
  return putRequest("/yethan/register/examZwk/edit", params);
};
// 职位库删除
export const deleteZwk= params => {
  return deleteRequest("/yethan/register/examZwk/del",params);
}