<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getZwk,
    editZwk,
    deleteZwk
  } from "@/api/admin/exam/createExamku.js"
  import {
    // getExamEasy,
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import changeExam from '@/components/change-exam.vue'
  // import {
  //   checkFile,
  // } from "@/api/index.js"
  /**
   * 黑名单
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      changeExam


    },
    data() {
      return {
        title: "考试职位库/",
        title2: "2021年二级造价工程师职业资格增报专业考试职位库",
        importTitle: "考试职位库导入",
        exportTitle: "考试职位库导出",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "职位库",
            active: true
          }
        ],
        tableList: [],
        exam: [],
        module: "ZWK",
        fileId: "",
        excelFields: [],
        years: [],
        addForm: {},
        showmodal: false,
        ksbmbh: "",
        ksnf: "",
        ksmc: "",
        select: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          keyword: "",
          ksnf: "",
          keyParam: "zwmc"
        },
        uploadLimit: {}

      };
    },
    methods: {
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      getList() {
        getZwk({
          ...this.pageData,
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      getksmc() {
        let val = this.select.ksbmbh
        let obj = this.exam.find(k => {
          return k.ksbmbh === val
        })
        this.ksmc = obj.ksmc

      },
      // 条件查询
      getlistByParam() {
        this.title2 = this.ksmc
        this.select.ksnf = this.ksnf
        this.select.ksmc = this.ksmc
        getZwk({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },

      //上传成功回调

      success() {
        this.getList()
      },
      // 上传
      uploadFile() {
        var _this = this;
        _this.$importModal().show({
          title: this.importTitle,
          module: this.module,
          ksbmbh: this.ksbmbh,
          success: this.success
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.addForm.ksbmbh = this.ksbmbh
        // this.dialogTitle = "修改考试类别"
      },
      editZwk() {
        let formDate = this.addForm
        formDate.sid = this.addForm.sid
        editZwk(formDate).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: res.message,
              duration: 3000,
            })
          }
          this.showmodal = false
          this.getList()
        })
      },
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.zwmc}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteZwk({
            ksbmbh: obj.ksbmbh ? obj.ksbmbh : this.ksbmbh,
            sids: obj.sid
          }).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //获取切换的考试
      getLastExam(params) {
        getNowExam().then(res => {
          if (res.status) {
            if (params == '') {
              this.ksbmbh = res.data.ksbmbh
              this.ksmc = res.data.ksmc
              this.pageData.ksnf = res.data.ksnf
            } else {
              this.ksbmbh = params
            }
            this.getList({
              ksbmbh: this.ksbmbh
            })
          }
        })
      },
      //申请职位查看
      reportView() {
        window.location.href = "/admin/reportView"
      }
    },
    mounted() {

      this.ksnf = new Date().getFullYear();
      this.getYear()
      this.ksbmbh = this.$route.query.ksbmbh ? this.$route.query.ksbmbh : ''
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ''
      this.getLastExam(this.ksbmbh)
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <input placeholder="输入职位名称查询" v-model="pageData.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="uploadFile"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入职位库</el-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="reportView">申请职位查看
                </el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle,type: 'xlsx', module:module, ksbmbh: ksbmbh,condition:pageData})">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle,type: 'dbf', module:module, ksbmbh: ksbmbh,condition:pageData})">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;"> 序号</th>
                    <th style="width: 10%;">主管部门</th>
                    <th style="width:6%">职位名称</th>
                    <th style="width:5%">职位编码</th>
                    <th style="width:3%">名额</th>
                    <th style="width:5%">缴费金额</th>
                    <th style="width:5%">报考比例</th>
                    <th style="width:6%">备注</th>
                    <th style="width:4%">市州代码</th>
                    <th style="width:8%">报考单位</th>
                    <th style="width:6%">范围对象</th>
                    <th style="width:6%">学历</th>
                    <th style="width:6%">专业</th>
                    <th style="width:6%">其他条件 </th>
                    <th style="width:5%">加试</th>
                    <th style="width:6%;">市州名称 </th>
                    <th style="width: 4%;">操作 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.bkbm}} </td>
                    <td> {{obj.zwmc}}</td>
                    <td> {{obj.zwbm}}</td>
                    <td>{{obj.me}}</td>
                    <td>{{obj.je1}}</td>
                    <td>{{obj.kkbl}}</td>
                    <td>
                      <div class="line1">{{obj.bz}}</div>
                    </td>
                    <td>{{obj.szdm}}</td>
                    <td>{{obj.bkdw}}</td>
                    <td>{{obj.fwdx}}</td>
                    <td>{{obj.xl}}</td>
                    <td>{{obj.zy}}</td>
                    <td>{{obj.qttl}}</td>
                    <td>{{obj.js}}</td>
                    <td>{{obj.sz}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two " @click="editItem(obj)"></i> <i
                        class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <changeExam></changeExam>
    <b-modal id="addClass" v-model="showmodal" centered title="考试职位库信息编辑" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">主管部门:</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.bkbm"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">职位名称:</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="40" v-model="addForm.zwmc"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">名额:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" v-model="addForm.me"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">职位编码:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" v-model="addForm.zwbm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">缴费金额:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" v-model="addForm.je1"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">
                报考比例:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength=" 10" v-model="addForm.kkbl"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 市州代码:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" v-model="addForm.szdm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">备注:</label>
              <div class="col-sm-10 p-0 flexList">
                <textarea rows="3" cols=" " maxlength="70" class="form-control w-100" v-model="addForm.bz"></textarea>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 报考单位:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.bkdw"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 范围对象:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="60" v-model="addForm.fwdx"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 学历:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="60" v-model="addForm.xl"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 专业:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="60" v-model="addForm.zy"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 其他条件:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="110" v-model="addForm.qttl"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 市州名称:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="20" v-model="addForm.sz"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15"> 加试:</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" v-model="addForm.js"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="editZwk">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
